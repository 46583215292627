<template>
  <section
    :class="dropdownClass"
    v-click-outside="(e) => handleOutsideClick(e)"
  >
    <div class="switch-player__teams">
      <div class="switch-player__team">
        <div class="switch-player__team__name">
          <div
            class="swatch"
            :style="{ backgroundColor: this.team1.color }"
          ></div>
          <span :style="{ color: this.team1.color }">Team 1</span>
        </div>
        <div class="switch-player__positions">
          <button
            class="switch-player__position"
            v-for="position in this.team1.positions"
            :key="position.id"
            @click="selectPosition(position)"
          >
            {{ position.position.short_name }}
          </button>
        </div>
      </div>
      <div class="switch-player__team">
        <div class="switch-player__team__name">
          <div
            class="swatch"
            :style="{ backgroundColor: this.team2.color }"
          ></div>
          <span :style="{ color: this.team2.color }">Team 2</span>
        </div>
        <div class="switch-player__positions">
          <button
            class="switch-player__position"
            v-for="position in this.team2.positions"
            :key="position.id"
            @click="selectPosition(position)"
          >
            {{ position.position.short_name }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import Helper from '@/helpers/SiteHelper'
  export default {
    name: 'SwitchPlayerDropdown',
    props: {
      dark: {
        type: Boolean,
        default: false,
      },
      teams: {
        type: [Object, Boolean],
      },
      closeDropdown: {
        type: Function,
      },
    },
    data: function() {
      return {
        team1: this.teams.team1,
        team2: this.teams.team2,
      }
    },
    methods: {
      selectPosition(payload) {
        this.$emit('newPositionSelected', payload)
        // this.closeDropdown()
      },
      handleOutsideClick(e) {
        let isChild = Helper.parentHasClass(
          e.target.parentElement,
          'switch-player',
        )
        if (!isChild) {
          this.closeDropdown()
        }
      },
    },
    computed: {
      dropdownClass() {
        let className = 'switch-player'
        className = this.dark ? `${className} switch-player--dark` : className
        return className
      },
    },
  }
</script>
