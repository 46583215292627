<template lang="html">
  <div class="LineupPlayer">
    <div class="LineupPlayer__wrapper" :style="{ backgroundColor: color }">
      <profile-pic
        :size="35"
        :src="picture"
        :alt="name"
        circle
        border
        v-if="player.player_id"
        :number="playingInGames"
      ></profile-pic>
      <div class="LineupPlayer__details" :key="player.player_id">
        <div class="LineupPlayer__name text-lg text-semi">{{ name }}</div>
        <div class="LineupPlayer__position text-bold">
          {{ player.position.short_name }}
        </div>
      </div>
      <div :class="ratedClass" @click="toggleRate" v-if="player.player_id">
        <svg-icon icon="star"></svg-icon>
      </div>
      <div :class="dropdownContainerClass">
        <action
          icon="add"
          size="sm"
          v-if="!player.player_id && sortedPlayers"
          transparent
          @actionClicked="toggleAssignment"
        ></action>
        <dropdown
          v-if="player.player_id"
          :items="items"
          keyProp="id"
          prop="label"
          dark
          @dropdown-item-click="handlePlayerAction"
        />
        <SwitchPlayer
          dark
          :teams="teams"
          v-if="showSwitch"
          :closeDropdown="toggleSwitch"
          @newPositionSelected="switchPosition"
        ></SwitchPlayer>
      </div>
    </div>
    <portal to="modal">
      <RateModal
        v-if="showRateForm"
        :player="player"
        :toggle="toggleRate"
        :rating="playerRating"
      ></RateModal>
      <AssignPlayerModal
        v-if="assignPlayer"
        :players="sortedPlayers"
        :team="team"
        :position="player.position"
        :toggle="toggleAssignment"
        :searchOptions="searchSettings"
        :color="color"
        @selectPlayer="addPlayerToPosition"
      ></AssignPlayerModal>
    </portal>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import SwitchPlayer from '@/components/Dropdowns/SwitchPlayer'
  import RateModal from '@/components/Modals/RateModal'
  import AssignPlayerModal from '@/components/Modals/AssignPlayerModal'

  export default {
    name: 'LineupPlayer',
    components: { RateModal, AssignPlayerModal, SwitchPlayer },
    props: {
      color: {
        type: String,
        required: true,
      },
      eventId: {
        type: Number,
        required: true,
      },
      player: {
        type: Object,
        required: true,
      },
      team: {
        required: true,
      },
      availablePlayers: {
        type: [Array, Boolean],
        required: false,
      },
      teams: {
        type: [Object, Boolean],
        required: false,
      },
    },
    data: function() {
      return {
        showRateForm: false,
        assignPlayer: false,
        showSwitch: false,
        searchSettings: {
          threshold: 0.4,
          keys: ['player.name', 'player.email'],
        },
        sortedPlayers: [],
        items: [
          {
            id: 'show',
            label: 'Show profile',
            icon: 'person',
          },
          {
            id: 'note',
            label: 'Create a note',
            icon: 'add-note',
          },
          {
            id: 'change',
            label: 'Move position',
            icon: 'switch',
          },
          {
            id: 'remove',
            label: 'Remove player',
            icon: 'remove-circle',
          },
        ],
      }
    },
    mounted() {
      this.sortPlayers(this.availablePlayers)
    },
    methods: {
      toggleRate() {
        this.showRateForm = !this.showRateForm
      },
      toggleSwitch() {
        this.showSwitch = false
      },
      toggleAssignment() {
        this.assignPlayer = !this.assignPlayer
      },
      handlePlayerAction(payload) {
        switch (payload.id) {
          case 'remove':
            this.removePlayerFromPosition()
            break
          case 'change':
            this.showSwitch = true
            break
          case 'note':
            this.$router.push(`/players/${this.player.player_id}/note`)
            break
          case 'show':
            this.$router.push(`/players/${this.player.player_id}`)
            break
          default:
            this.changePositionForPlayer(payload)
            break
        }
      },
      switchPosition(payload) {
        let data = {
          position1: payload.id,
          player2: this.player.player_id,
          position2: this.player.id,
          player1: payload.player_id,
          game: this.player.game_id,
        }
        this.$store.dispatch('games/SWITCH_PLAYERS', data)
      },
      sortPlayers(players) {
        let shortName = this.player.position.short_name
        if (players.length > 0) {
          let sortable = [...players]
          this.sortedPlayers = sortable.sort((a, b) => {
            let x =
              a.player.preffered_positions.length > 1
                ? [
                    a.player.preffered_positions[0].short_name,
                    a.player.preffered_positions[1].short_name,
                  ].indexOf(shortName) > -1
                : false
            let y =
              b.player.preffered_positions.length > 1
                ? [
                    b.player.preffered_positions[0].short_name,
                    b.player.preffered_positions[1].short_name,
                  ].indexOf(shortName) > -1
                : false

            return y - x
          })
        }
      },
      changePositionForPlayer(payload) {
        let player_id = this.player.player_id
        this.$store.dispatch('games/UPDATE_PLAYER_LINEUP_POSITION', {
          id: payload.id,
          player_id: player_id,
          game_id: this.player.game_id,
        })
      },
      addPlayerToPosition(payload) {
        let data = {
          id: this.player.id,
          player_id: payload.player_id,
          game_id: this.player.game_id,
        }
        this.$store.dispatch('games/UPDATE_PLAYER_LINEUP_POSITION', data)
      },
      removePlayerFromPosition() {
        let data = {
          id: this.player.id,
          player_id: null,
          game_id: this.player.game_id,
        }
        this.$emit('playerRemoved')
        this.$store.dispatch('games/UPDATE_PLAYER_LINEUP_POSITION', data)
      },
    },
    computed: {
      ...mapState({
        ratedLineups: (state) => state.user.profile.rated,
        ratings: (state) => state.user.profile.ratings,
      }),
      playerRating() {
        return this.ratings.find(
          (item) => item.player_lineup_id == this.player.id,
        )
      },
      name() {
        let current = this.player.player
        return current && current.name ? current.name : 'Unassigned'
      },
      ratedClass() {
        return this.ratedLineups.indexOf(this.player.id) !== -1
          ? 'LineupPlayer__rate LineupPlayer__rate--rated'
          : 'LineupPlayer__rate'
      },
      picture() {
        let current = this.player.player
        return current && current.photo_url ? current.photo_url : null
      },
      isUnassigned() {
        let current = this.player.player
        return current && current.name ? false : true
      },
      playingInGames() {
        let current = this.player.player
        return current
          ? current.games.filter((x) => x.event_id == this.eventId).length
          : false
      },
      dropdownContainerClass() {
        return this.player.player_id
          ? 'LineupPlayer__dropdown'
          : 'LineupPlayer__dropdown LineupPlayer__dropdown--unassigned'
      },
    },
    watch: {
      availablePlayers: {
        deep: true,
        handler(players) {
          this.sortPlayers(players)
        },
      },
    },
  }
</script>
