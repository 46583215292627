<template>
  <Modal>
    <div slot="header">
      <div class="modal-header__content">
        <div class="modal-header__player">
          <h3 class="text-bold text-lg mb-12 text-white">Assign player</h3>
          <div class="assignment-modal__team text-semi">
            <div class="swatch" :style="{ backgroundColor: color }"></div>
            <span class="mr-16" :style="{ color: color }">
              Team {{ team }}
            </span>
            <span class="text-grey">/ {{ position.name }}</span>
          </div>
        </div>
        <button class="modal-header__close" @click="toggle">
          <svg-icon icon="x"></svg-icon>
        </button>
      </div>
    </div>
    <div slot="body">
      <div class="assignment-modal__search">
        <input
          type="search"
          class="assignment-modal__search-input"
          v-model="searchable"
          placeholder="Type to search"
          ref="search"
        />
        <svg-icon icon="search"></svg-icon>
      </div>
      <simplebar class="assignment-modal__list">
        <div
          class="assignment-modal__player"
          v-for="player in filteredItems"
          :key="player.player.id"
          @click="selectPlayer(player)"
        >
          <profile-pic
            :src="player.player.photo_url"
            :size="40"
            circle
          ></profile-pic>
          <span class="ml-16 text-semi">{{ player.player.name }}</span>
          <div class="ml-auto assignment-modal__positions">
            <tag-item
              v-for="position in player.player.preffered_positions"
              :key="position.id"
              >{{ position.short_name }}</tag-item
            >
          </div>
        </div>
      </simplebar>
    </div>
  </Modal>
</template>

<script>
  import Modal from '@/components/Modal'
  export default {
    name: 'AssignPlayerModal',
    components: { Modal },
    props: {
      toggle: {
        type: Function,
        required: true,
      },
      players: {
        required: false,
      },
      position: {
        required: false,
      },
      team: {
        required: false,
      },
      color: {
        type: String,
        required: false,
      },
      searchOptions: {
        type: [Object, Boolean],
        default: false,
      },
    },
    data: function() {
      return {
        searchable: '',
        filteredItems: this.players,
      }
    },
    methods: {
      selectPlayer(payload) {
        this.$emit('selectPlayer', payload)
        this.toggle()
      },
    },
    watch: {
      searchable(term) {
        if (term.length > 2) {
          this.$search(term, this.players, this.searchOptions).then(
            (results) => {
              this.filteredItems = results
            },
          )
        } else {
          this.filteredItems = this.players
        }
      },
    },
  }
</script>
