<template lang="html">
  <div class="Dashboard">
    <div v-if="game == false">
      <div class="container-full">
        <div class="container--center">
          <empty-message>
            Game not found!
          </empty-message>
        </div>
      </div>
    </div>
    <div v-if="game">
      <dashboard-top>
        <screen-title :title="this.$store.state.user.team.name"></screen-title>
      </dashboard-top>
      <Breadcrumbs :title="`${game.name}`" />
      <dashboard-top>
        <section-title :title="game.event.name" class="mb-8">
          <div class="btn btn--dark">{{ game.court.name }}</div>
          <Timer :game="game" ref="timer" />
        </section-title>
      </dashboard-top>

      <div class="container-full">
        <div class="dashboard__section">
          <div class="Teams">
            <div
              class="Teams__item"
              :style="{ color: game.court.team_1_color }"
            >
              <swatches
                v-model="game.court.team_1_color"
                @input="updateTeamColor"
                :colors="swatches"
                popover-to="right"
                :trigger-style="{
                  width: '24px',
                  height: '24px',
                  borderRadius: '5px',
                }"
              />
              <span>Team 1</span>
            </div>
            <div
              class="Teams__item"
              :style="{ color: game.court.team_2_color }"
            >
              <swatches
                v-model="game.court.team_2_color"
                @input="updateTeamColor"
                :colors="swatches"
                popover-to="right"
                :trigger-style="{
                  width: '24px',
                  height: '24px',
                  borderRadius: '5px',
                }"
              />
              <span>Team 2</span>
            </div>
          </div>
          <GameView :game="game"></GameView>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Swatches from 'vue-swatches'

  import GameView from '@/views/Games/partials/GameView'
  import 'vue-swatches/dist/vue-swatches.min.css'

  export default {
    name: 'Game',
    components: { GameView, Swatches },
    data: function() {
      return {
        swatches: [
          '#4150fb',
          '#ed5440',
          '#171819',
          '#C0C0C0',
          '#12A621',
          '#FFFF00',
          '#FF1493',
          '#9400D3',
          '#800000',
          '#A52A2A',
          '#FF4500',
        ],
      }
    },
    mounted() {
      this.$store.dispatch('games/GET_GAMES')
    },
    methods: {
      updateTeamColor() {
        this.game.team_1_color = this.game.court.team_1_color
        this.game.team_2_color = this.game.court.team_2_color
        this.$store.dispatch('games/UPDATE_TEAMS_COLORS', this.game)
      },
    },
    computed: {
      game() {
        return this.$store.getters['games/getGame'](this.$route.params.id)
      },
    },
  }
</script>
