<template>
  <content-loader
    :width="206"
    :height="60"
    :speed="2"
    viewBox="0 0 60 206"
    :primaryColor="primaryColor"
    secondaryColor="#D1D1D1"
  >
    <rect x="10" y="0" rx="5" ry="5" width="206" height="60" />
  </content-loader>
</template>

<script>
  import { ContentLoader } from "vue-content-loader"
  export default {
    components: { ContentLoader },
    props: {
      primaryColor: {
        type: String,
        default: '#fff',
      }
    }
  }
</script>
