<template lang="html">
  <section class="Court__wrapper">
    <div class="Court">
      <div class="Court__line"></div>
      <div class="Court__line"></div>
      <div class="Court__center"></div>
    </div>
    <div class="Court__players" v-if="!loaded">
      <div class="Position" v-for="(position, index) in positions" :key="index">
        <div
          class="LineupPlayer"
          v-for="(player, x) in position"
          :key="`${index}${x}`"
        >
          <div class="LineupPlayer__wrapper LineupPlayer__wrapper--loader">
            <LineupPlayerLoader />
          </div>
        </div>
      </div>
    </div>
    <div class="Court__players" v-else :key="team_1.game_id">
      <div class="Position" v-for="(position, index) in positions" :key="index">
        <LineupPlayer
          v-for="(player, x) in position"
          :event-id="game.event_id"
          :key="`${index}${x}${
            getTeamPlayer(player.team, player.position).player_id
          }`"
          :player="getTeamPlayer(player.team, player.position)"
          :color="getColorForTeam(player.team)"
          :team="player.team"
          :availablePlayers="players"
          :teams="getTeams()"
          @playerRemoved="loadLineups"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import LineupPlayer from '@/partials/Players/LineupPlayer';
  import LineupPlayerLoader from '@/components/Loaders/LineupPlayerLoader';
  import { mapState } from 'vuex';
  import api from '@/api';
  export default {
    name: 'GameView',
    components: { LineupPlayer, LineupPlayerLoader },
    props: {
      game: {
        type: Object,
        required: true,
      },
    },
    data: function () {
      return {
        submissions: null,
        positions: [
          [
            {
              position: 'WD',
              team: 1,
            },
            {
              position: 'WA',
              team: 2,
            },
          ],
          [
            {
              position: 'GA',
              team: 1,
            },
            {
              position: 'GD',
              team: 2,
            },
          ],
          [
            {
              position: 'GK',
              team: 1,
            },
            {
              position: 'GS',
              team: 2,
            },
          ],
          [
            {
              position: 'C',
              team: 1,
            },
            {
              position: 'C',
              team: 2,
            },
          ],
          [
            {
              position: 'GS',
              team: 1,
            },
            {
              position: 'GK',
              team: 2,
            },
          ],
          [
            {
              position: 'GA',
              team: 2,
            },
            {
              position: 'GD',
              team: 1,
            },
          ],
          [
            {
              position: 'WD',
              team: 2,
            },
            {
              position: 'WA',
              team: 1,
            },
          ],
        ],
      };
    },
    created() {
      this.loadLineups();
    },
    methods: {
      loadLineups() {
        this.$store.dispatch('games/GET_GAME_LINEUPS', this.game);
        api.http.get(`events/${this.game.event_id}/submissions`).then((res) => {
          this.submissions = res.body;
        });
      },
      getTeamPlayer(team, position) {
        let curr_team = team == 1 ? this.team_1 : this.team_2;
        return curr_team.find((x) => x.position.short_name == position);
      },
      getTeams() {
        let result = {
          team1: { positions: this.team_1 },
          team2: { positions: this.team_2 },
        };
        result.team1.color = this.game.court.team_1_color;
        result.team2.color = this.game.court.team_2_color;
        return result;
      },
      getColorForTeam(team) {
        return team == 1
          ? this.game.court.team_1_color
          : this.game.court.team_2_color;
      },
    },
    computed: {
      ...mapState({
        lineups: (state) => state.games.lineups,
        team_1: (state) =>
          state.games.lineups
            ? state.games.lineups.filter((item) => item.name === 'Team 1')[0]
                .squad
            : false,
        team_2: (state) =>
          state.games.lineups
            ? state.games.lineups.filter((item) => item.name === 'Team 2')[0]
                .squad
            : false,
      }),
      assigned_players() {
        return [...this.lineups[0].squad, ...this.lineups[1].squad].map(
          (item) => item.player_id,
        );
      },
      players() {
        let result = false;
        if (this.submissions) {
          let submissions = this.submissions.filter(
            (player) => player.attendance !== 'absent',
          );
          let otherPlayers = submissions.filter(
            (item) => this.assigned_players.indexOf(item.player_id) == -1,
          );
          result = otherPlayers.filter((player) => {
            let simGames = player.player.games.filter(
              (game) =>
                game.start_time == this.game.start_time &&
                game.event_id === this.game.event_id,
            );
            return !(simGames.length > 0);
          });
        }
        return result;
      },
      loaded() {
        return (
          this.team_1 &&
          this.team_2 &&
          this.$route.params.id == this.lineups[0].game_id
        );
        // return false
      },
    },
  };
</script>
