<template>
  <Modal>
    <div slot="header">
      <div class="modal-header__content">
        <div class="modal-header__player">
          <h3 class="text-bold text-lg mb-8 text-white">Rate player</h3>
          <div class="modal__player">
            <profile-pic
              :src="player.player.photo_url"
              :alt="player.player.name"
              :size="40"
            />
            <div class="ml-8 text-bold">{{ player.player.name }}</div>
          </div>
        </div>
        <button class="modal-header__close" @click="toggle">
          <svg-icon icon="x"></svg-icon>
        </button>
      </div>
    </div>
    <div slot="body">
      <RateForm
        :player="player"
        :rating="this.rating"
        @ratedPlayer="toggle"
        minimal
      ></RateForm>
    </div>
  </Modal>
</template>

<script>
  import Modal from '@/components/Modal'
  import RateForm from '@/components/Forms/RateForm'

  export default {
    name: 'RateModal',
    components: { RateForm, Modal },
    props: {
      showRateForm: {
        type: Boolean,
        default: false,
      },
      player: {
        required: false,
      },
      toggle: {
        type: Function,
        required: true,
      },
      rating: {},
    },
  }
</script>
