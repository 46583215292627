var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Dashboard"},[(_vm.game == false)?_c('div',[_c('div',{staticClass:"container-full"},[_c('div',{staticClass:"container--center"},[_c('empty-message',[_vm._v(" Game not found! ")])],1)])]):_vm._e(),(_vm.game)?_c('div',[_c('dashboard-top',[_c('screen-title',{attrs:{"title":this.$store.state.user.team.name}})],1),_c('Breadcrumbs',{attrs:{"title":("" + (_vm.game.name))}}),_c('dashboard-top',[_c('section-title',{staticClass:"mb-8",attrs:{"title":_vm.game.event.name}},[_c('div',{staticClass:"btn btn--dark"},[_vm._v(_vm._s(_vm.game.court.name))]),_c('Timer',{ref:"timer",attrs:{"game":_vm.game}})],1)],1),_c('div',{staticClass:"container-full"},[_c('div',{staticClass:"dashboard__section"},[_c('div',{staticClass:"Teams"},[_c('div',{staticClass:"Teams__item",style:({ color: _vm.game.court.team_1_color })},[_c('swatches',{attrs:{"colors":_vm.swatches,"popover-to":"right","trigger-style":{
                width: '24px',
                height: '24px',
                borderRadius: '5px',
              }},on:{"input":_vm.updateTeamColor},model:{value:(_vm.game.court.team_1_color),callback:function ($$v) {_vm.$set(_vm.game.court, "team_1_color", $$v)},expression:"game.court.team_1_color"}}),_c('span',[_vm._v("Team 1")])],1),_c('div',{staticClass:"Teams__item",style:({ color: _vm.game.court.team_2_color })},[_c('swatches',{attrs:{"colors":_vm.swatches,"popover-to":"right","trigger-style":{
                width: '24px',
                height: '24px',
                borderRadius: '5px',
              }},on:{"input":_vm.updateTeamColor},model:{value:(_vm.game.court.team_2_color),callback:function ($$v) {_vm.$set(_vm.game.court, "team_2_color", $$v)},expression:"game.court.team_2_color"}}),_c('span',[_vm._v("Team 2")])],1)]),_c('GameView',{attrs:{"game":_vm.game}})],1)])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }