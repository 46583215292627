<template lang="html">
  <ValidationObserver v-slot="{ handleSubmit }" slim ref="rateForm">
    <form
      @submit.prevent="handleSubmit(handleDivisionSubmit)"
      :class="`Form ${minimal ? 'Form--rate-minimal' : ''}`"
    >
      <formly-form
        :form="form"
        :model="model"
        :fields="fields"
        tag="div"
        class="Form__group"
      ></formly-form>
      <button v-if="!minimal" class="btn btn--outlined">Confirm</button>
      <div v-else class="text-center text-bold text-md">{{ rateVal }}</div>
    </form>
  </ValidationObserver>
</template>

<script>
  import data from '@/config/rating'
  export default {
    name: 'RatingForm',
    props: {
      player: {
        type: Object,
        required: true,
      },
      minimal: {
        type: Boolean,
        default: false,
      },
      rating: {},
    },
    data: function() {
      return {
        model: {
          rating: this.rating ? this.rating.rating : null,
        },
        form: {},
        fields: [
          {
            key: 'rating',
            type: 'rate-field',
            rules: 'required',
            templateOptions: {
              label: this.minimal ? false : 'Rate',
              layout: 'full',
              count: 5,
            },
          },
        ],
      }
    },
    created() {
      // if (this.rating) {
      //   this.model.rating = this.rating.rating
      // }
    },
    methods: {
      handleDivisionSubmit() {
        let payload = {
          player_lineup_id: this.player.id,
          player: this.player.player.id,
          rating: this.model.rating,
        }

        this.$store.dispatch('players/RATE_PLAYER', payload).then(() => {
          this.sanatizeForm()
          this.goBack()
        })
      },
      sanatizeForm() {
        this.$refs.rateForm.reset()
      },

      goBack() {
        this.$emit('ratedPlayer')
      },
    },
    computed: {
      rateVal() {
        return this.model.rating
          ? data[this.model.rating]
          : 'Select your rating'
      },
    },
    watch: {
      model: {
        deep: true,
        handler() {
          if (this.minimal) {
            this.handleDivisionSubmit()
          }
        },
      },
    },
  }
</script>
